import { isBackOfficePathname } from "@/modules/routing/helpers/isBackOfficePathname"

// List of the FO page paths NOT supporting UI Shift

const PAGE_PATHNAMES_WITHOUT_UI_SHIFT = ["corporate", "talent-connect"]

// List of the BO page paths supporting UI Shift
const BO_PAGE_PATHNAMES_WITH_UI_SHIFT = ["career-explorer"]

/**
 * UIShift is only available for some FO/BO features
 * See routing-config.json
 **/
export function isUiShiftCompatibleWithPathname(pathname: string): boolean {
  const [, , pagePath, boPagePath] = pathname.split("/")

  if (isBackOfficePathname(pathname)) {
    return BO_PAGE_PATHNAMES_WITH_UI_SHIFT.includes(boPagePath)
  }

  return !PAGE_PATHNAMES_WITHOUT_UI_SHIFT.includes(pagePath)
}
